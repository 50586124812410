import React from 'react';
import './StudyTable.css';

interface StudyModel {
  studyId: number;
  transcriptId: number;
  studyUrl: string;
  studyTitle: string;
  studyAbstract: string | null;
  studyKeywords: string[] | null;
  dateAdded: string;
  dateUpdated: string | null;
  addedBy: string;
  updatedBy: string | null;
}

interface Props {
  data: StudyModel[];
  onEdit: (study: StudyModel) => void;
  onDelete: (study: StudyModel) => void;
}

function StudyTable({ data, onEdit, onDelete }: Props): JSX.Element {
  if (data.length == 0) {
    return <p>No associated Studies</p>;
  }
  return (
    <table>
      <thead>
        <tr>
          <th>Title</th>
          <th>Url</th>
          <th>Keywords</th>
          <th>Abstract</th>
          <th>Date Updated</th>
          <th>Updated By</th>
          <th>Date Added</th>
          <th>Added By</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item.studyId}>
            <td>{item.studyTitle}</td>
            <td>{item.studyUrl}</td>
            <td>{item.studyKeywords?.join(',')}</td>
            <td>{item.studyAbstract}</td>
            <td>{item.dateUpdated}</td>
            <td>{item.updatedBy}</td>
            <td>{item.dateAdded}</td>
            <td>{item.addedBy}</td>
            <td>
              <button onClick={() => onEdit(item)}>Edit</button>
              <button onClick={() => onDelete(item)}>Delete</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default StudyTable;
