import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoLogoElectron } from 'react-icons/io5';
import './SignIn.page.css';

const Signin: React.FC = () => {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const res = await fetch('/signin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!res.ok) {
        throw new Error('Invalid credentials. Please try again.');
      }

      const data = await res.json();
      localStorage.setItem('token', data.token); // Store JWT token
      navigate('/'); // Redirect to protected route
    } catch (error) {
      setError('Invalid credentials. Please try again.'); // Show error message
    }
  };

  return (
    <div className="signin-container">
      <div className="logoName">
        <IoLogoElectron className="logo" />
        <h3>Video Search AI</h3>
      </div>
      <div className="signin-box">
        <h2 className="signin-title">Sign In</h2>
        {error && <p className="signin-error">{error}</p>}
        <form onSubmit={handleSubmit}>
          <input
            className="signin-input"
            type="email"
            name="email"
            placeholder="abc@xyz.com"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <input
            className="signin-input"
            type="password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            required
          />
          <button className="signin-button" type="submit">
            Continue with email
          </button>
        </form>
        <p className="signin-terms">
          Don't have an account? <a href="/signup">Sign Up</a>.
        </p>
      </div>
    </div>
  );
};

export default Signin;
