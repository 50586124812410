import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoLogoElectron } from 'react-icons/io5';
import './SignUp.page.css';
import img1 from "../../../images/capsule-grp.png";
import img2 from "../../../images/illustration-grp.png";
import newLogo from "../../../images/newLogo.png";

const Signup: React.FC = () => {
  const [formData, setFormData] = useState({
    userName: '',
    email: '',
    password: '',
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const res = await fetch('/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!res.ok) {
        throw new Error('Sign up failed. Please try again.');
      }

      const data = await res.json();
      localStorage.setItem('token', data.token); // Store token after signup
      navigate('/admin'); // Redirect to protected route
    } catch (error) {
      setError('Sign up failed. Please try again.');
    }
  };

  return (
    // <div className="signup-container">
    //   <div className="logoName">
    //     <IoLogoElectron className="logo" />
    //     <h3>Video Search AI</h3>
    //   </div>
    //   <div className="signup-box">
    //     <h2 className="signup-title">Sign Up</h2>
    //     {error && <p className="signup-error">{error}</p>}
    //     <form onSubmit={handleSubmit}>
    //       <input
    //         type="text"
    //         name="username"
    //         placeholder="Username"
    //         value={formData.username}
    //         onChange={handleChange}
    //         className="signup-input"
    //         required
    //       />
    //       <input
    //         type="email"
    //         name="email"
    //         placeholder="Email"
    //         value={formData.email}
    //         onChange={handleChange}
    //         className="signup-input"
    //         required
    //       />
    //       <input
    //         type="password"
    //         name="password"
    //         placeholder="Password"
    //         value={formData.password}
    //         onChange={handleChange}
    //         className="signup-input"
    //         required
    //       />
    //       <button type="submit" className="signup-button">
    //         Sign Up
    //       </button>
    //     </form>
    //   </div>
    // </div>


    //Temporary Homepage + SignUp page
    <div className="signup-container">
      {/* Left Side */}
      <div className="left-sign-up">
        <div className="logo-container">
        <img src={newLogo} />
        </div>
        <h1 className="main-heading">Video Answers at Fingertips</h1>
        <div className="signup-box">
        <h2 className="signup-title">Sign Up</h2>
        {error && <p className="signup-error">{error}</p>}
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="username"
            placeholder="Username"
            value={formData.userName}
            onChange={handleChange}
            className="signup-input"
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            className="signup-input"
            required
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            className="signup-input"
            required
          />
          <button type="submit" className="signup-button">
            Sign Up
          </button>
        </form>
      </div>
      </div>

      {/* Right Side */}
      <div className="right-sign-up">
        <div className="right-content">
          <div className="image-container">
          <p className="caption">“ Video Search AI, Find me a video related to Vitamin-D and it's Effects</p>
          <img src={img1} alt="Capsule" className="floating-image" />
          </div>
          <div className="image-container2">
            <img src={img2} alt="Illustration" className="floating-image2" />
            <p className="caption2">“ Also find me all the related references for the video</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
