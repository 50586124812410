import React, { ChangeEvent } from 'react';
import './InputField.css';

interface Props {
  value: string;
  label: string;
  onValueChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

function InputField({ value, label, onValueChange }: Props) {
  return (
    <div className="InputContainer">
      <input
        type="text"
        value={value}
        placeholder={label}
        onChange={onValueChange}
        className="InputField"
      ></input>
    </div>
  );
}

export default InputField;
