import React from 'react';
import './SourcesTextField.css';

interface Study {
  studyTitle: string;
  studyURL: string;
  studyAbstract: string;
  studyKeywords: string;
}

interface Source {
  videoTitle: string;
  videoURL: string;
  relevancy: string;
  associatedStudies: Study[];
}

export interface Props {
  sources: Source[];
}

function SourcesTextField({ sources }: Props) {
  return (
    <div className="parentReferenceCard">
      {sources.map((source, index) => (
        <div key={index} className="referenceCard">
          <a href={source.videoURL} target="_blank" rel="noopener noreferrer" className="hyperlink">
            <h3>{source.videoTitle}</h3>
          </a>
          <div className="relevancyContainer">
            <h4 className="relevancyScore">{source.relevancy}% Relevancy</h4>
            <div className="progressBarContainer">
              <div className="progressBar" style={{ width: `${source.relevancy}%` }} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default SourcesTextField;
