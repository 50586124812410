import React, { ChangeEvent } from 'react';
import './LargeInputField.css';

interface Props {
  value: string;
  label: string;
  onValueChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

function LargeInputField({ value, label, onValueChange }: Props) {
  return (
    <input
      type="text"
      value={value}
      placeholder={label}
      onChange={onValueChange}
      className="LargeInputField"
    ></input>
  );
}

export default LargeInputField;
