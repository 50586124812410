import React, { useState } from 'react';

import './StudyForm.css';
import LoadingSpinner from '../../components/LoadingSpinner';

export interface Props {
  onSubmit: () => void;
  onBack: () => void;
  onNameChange: (name: string) => void;
  onAbstractChange: (abstract: string) => void;
  onUrlChange: (url: string) => void;
  onKeywordAdded: (keyword: string) => void;
  onKeywordRemoved: (keyword: string) => void;
  isLoading: boolean;
  studyName: string;
  studyUrl: string;
  studyAbstract: string;
  studyKeywords: string[];
}

function StudyForm({
  onSubmit,
  onBack,
  onNameChange,
  onAbstractChange,
  onUrlChange,
  onKeywordAdded,
  onKeywordRemoved,
  isLoading,
  studyName,
  studyUrl,
  studyAbstract,
  studyKeywords,
}: Props): JSX.Element {
  const [newKeyword, setNewKeyword] = useState<string>('');

  const handleAddKeyword = () => {
    if (newKeyword.trim()) {
      onKeywordAdded(newKeyword.trim());
      setNewKeyword(''); // Reset the input field
    }
  };

  const handleSubmitPressed = () => {
    if (studyName !== '' && studyName.length > 5 && studyUrl !== '' && studyUrl.length > 5) {
      onSubmit();
    }
  };

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (
        <div className="create-study-container">
          <button type="button" className="backButton" onClick={onBack}>
            Back
          </button>
          <div className="form-group">
            <label className="form-label">
              Study Name:
              <input
                type="text"
                value={studyName}
                onChange={(e) => onNameChange(e.target.value)}
                className="form-input"
              />
            </label>
          </div>
          <div className="form-group">
            <label className="form-label">
              Study URL:
              <input
                type="text"
                value={studyUrl}
                onChange={(e) => onUrlChange(e.target.value)}
                className="form-input"
              />
            </label>
          </div>
          <div className="form-group">
            <label className="form-label">
              Study Abstract:
              <input
                type="text"
                value={studyAbstract}
                onChange={(e) => onAbstractChange(e.target.value)}
                className="form-input"
              />
            </label>
          </div>

          {/* Keywords Dropdown */}
          <div className="form-group">
            <label className="form-label">Keywords:</label>
            <ul className="keyword-list">
              {studyKeywords.map((keyword) => (
                <li key={keyword} className="keyword-item">
                  {keyword}
                  <button
                    type="button"
                    className="remove-keyword-btn"
                    onClick={() => onKeywordRemoved(keyword)}
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ul>
          </div>

          {/* Add Keyword Field */}
          <div className="form-group keyword-add">
            <input
              type="text"
              value={newKeyword}
              onChange={(e) => setNewKeyword(e.target.value)}
              placeholder="Add a keyword"
              className="keyword-input"
            />
            <button type="button" className="add-keyword-btn" onClick={handleAddKeyword}>
              Add
            </button>
          </div>

          {/* Submission and Back Buttons */}
          <div className="form-group submit-group">
            <button type="button" className="submit-btn" onClick={handleSubmitPressed}>
              Submit
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default StudyForm;
