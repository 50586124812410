import React, { ChangeEvent, useEffect, useState } from 'react';
import './Chat.page.css';
import '../../ui/Shared.css';
import { IoMdSend } from 'react-icons/io';
import { IoLogoElectron } from 'react-icons/io5';
import { IoSettingsOutline } from 'react-icons/io5';
import InputField from '../../ui/components/InputField';
import DropDownMenu from '../../ui/components/DropDownMenu';
import NumberInputField from '../../ui/components/NumberInputField';
import SourcesTextField from '../../ui/components/SourcesTextField';
import LargeInputField from '../../ui/components/LargeInputField';
import { useNavigate } from 'react-router-dom';
import SideBar from '../../ui/components/SideBar';

const isMock = process.env.REACT_APP_MOCK === 'true';

interface Source {
  videoTitle: string;
  videoURL: string;
  relevancy: string;
  associatedStudies: {
    studyTitle: string;
    studyURL: string;
    studyAbstract: string;
    studyKeywords: string;
  }[];
}

interface TrimmedHistory{
  chatHistoryId:number,
  text:string,
}

function ChatPage() {
  const token = localStorage.getItem('token'); // Retrieve the token
  const nav = useNavigate();
  const [query, setQuery] = useState<string>('');
  const [hide, setHide] = useState(true);
  const [educationLevel, setEducationLevel] = useState<string>('A high school student');
  const levels = ['Short', 'Medium', 'Long'];
  const [detailLevel, setDetailLevel] = useState<string>(levels[0]);
  const [threshold, setThreshold] = useState<number>(40);
  const [botResponse, setBotResponse] = useState<string>('');
  const [sources, setSources] = useState<Source[]>([]);
  const [chatId, setChatId] = useState<number>(-1); // -1 to indicate no chatId is assigned yet, perhapse null would be better?
  const [ws, setWs] = useState<WebSocket | null>(null);

  const [recentHistory, setRecentHistory] = useState<TrimmedHistory[]>([])
  // TODO need to make a more robust method of selecting entities
  const [selectableEntities, setSelectableEntities] = useState<string[]>([]);
  const [selectedEntities, setSelectedEntities] = useState<string[]>([]);

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const handleEducationLevelChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEducationLevel(event.target.value);
  };

  const handleThresholdChange = (value: number) => {
    setThreshold(value);
  };

  const getInitialEntities = async () =>{
    const url = '/validentities'
    try{
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Include the token in the Authorization header
        },
      })
      if(!response.ok){
        if(response.status === 401){
          nav('/signin');
        }
        throw new Error(`Network response was not ok`)
        // TODO should set a variable here
      }
      const json = await response.json();
      const entities:string[] = json;
      setSelectableEntities(entities);
      setSelectedEntities(entities);
    } catch(error){
      // TODO should set a variable here
    }
  }

  const startWebSocket = (callback?: () => void) => {
    // Close the previous WebSocket if it's already open
    const mockEndpoint = isMock ? '/mock' : '';
    
    if (ws) {
      ws.close();
      setWs(null);
    }

    // Initialize the WebSocket connection
    let socket: WebSocket;
    if (process.env.REACT_APP_ENV === 'local') {
      socket = new WebSocket(`ws://localhost:3000/chatbot${mockEndpoint}`);
    } else {
      socket = new WebSocket(
        `${window.location.origin.replace(/^http/, 'ws')}/chatbot${mockEndpoint}`,
      );
    }

    // Set the WebSocket on open connection and call the callback if provided
    socket.onopen = () => {
      if(token){
        socket.send(JSON.stringify({ type: "auth", token })); // Send the token as the first message
      }
      setWs(socket);
      if (callback) {
        callback(); // Send query only when WebSocket is open
      }
    };

    // Handle incoming messages
    socket.onmessage = (event: MessageEvent) => {
      const data = JSON.parse(event.data);

      switch (data.type) {
        case 'chatId':
          setChatId(data.id!); // Assume chatId is number
          break;
        case 'aiResponseChunk':
          setBotResponse((prev) => prev + data.text!);
          break;
        case 'messageEnd':
          // use this if you need a flag for the message is finished
          break;
        case 'noSources':
          setBotResponse(data.text!);
          break;
        case 'aiSources':
          if (data.sources) {
            try {
              const newSources = data.sources as Source[];
              setSources(newSources);
            } catch (error) {
              console.log(`There was an error when getting sources`);
              console.log(error);
            }
          }
          break;  
        case 'inputError':
          console.log(`an error occured with inputs`)
          // TODO prompt user that an error occured and to adjust inputs?
          break;
        case 'serverError':
          console.log(`an error occured with the server`)
          // TODO Tell user server had an issue and maybe try again later
          break;
        case 'authError':
          nav('/signin');
          break;
        default:
          console.error('Unknown message type:', data.type);
          console.log(data);
      }
    };

    socket.onerror = (error: Event) => {
      console.error('WebSocket error:', error);
    };

    // Cleanup WebSocket connection on component unmount
    return () => {
      socket.close();
    };
  };

  const sendQuery = () => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      const payload = {
        query: query,
        educationLevel: educationLevel,
        detailLevel: detailLevel,
        threshold: threshold,
        selectedEntities: selectedEntities,
        chatId: chatId === -1 ? null : chatId,
      };

      ws.send(JSON.stringify(payload));
      setBotResponse('');
      setQuery('');
    }
  };

  const handleSubmit = () => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      sendQuery();
    } else {
      startWebSocket(sendQuery); // Ensure WebSocket is open before sending the query
    }
  };

  const getRecentHistory = async () =>{
    const url = '/chat/history'
    try{
      const jsonData = {
        maxHistorySize:10
      }
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Include the token in the Authorization header
        },
        body:JSON.stringify(jsonData)
      })
      if(!response.ok){
        if(response.status === 401){
          nav('/signin');
        }
        throw new Error(`Network response was not ok`)
        // TODO should set a variable here
      }
      const json = await response.json();
      const history = JSON.parse(json) as TrimmedHistory[]
      setRecentHistory(history);
    } catch(error){
      // TODO should set a variable here
    }
  }

  // Get required background info
  useEffect(()=>{
    getInitialEntities()
    getRecentHistory()
  }, [])
  
  // Set up the WebSocket on component mount
  useEffect(() => {
    startWebSocket();
    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, []);

  const handleHide = () => {
    setHide(!hide);
  };

  return (
    <div className="chatPageContainer">
      <div>
      <SideBar chatHistory={recentHistory}  />
      </div>
      <div className="right-chatContainer">
      <header className="chatPageheader">
        <div className="headerWrapper">
        <div className="logoName">
      <img src={'/logo54.png'} alt="Logo" className="logo" />
      <h3>Video Search AI</h3>
    </div>
          <div className="gearIconDiv">
            <IoSettingsOutline
              className="gearIcon"
              onClick={() => {
                handleHide();
              }}
            />
            <div className={`chatPageSetting ${hide ? 'hidden' : ''}`}>
              <div>
                <p>Education Level:</p>
                <InputField
                  value={educationLevel}
                  label={'Education Level'}
                  onValueChange={handleEducationLevelChange}
                ></InputField>
              </div>
              <div>
                <p> Detail Amount: </p>
                <DropDownMenu
                  choices={levels}
                  onSelect={(index) => setDetailLevel(index)}
                ></DropDownMenu>
              </div>
              <div>
                <p>Relevancy Threshold:</p>
                <NumberInputField
                  value={threshold}
                  label={'Threshold'}
                  onValueChange={handleThresholdChange}
                ></NumberInputField>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="chatPageMainWrapper">
        <div className={botResponse !== '' ? 'chatPageReferences' : ''}>
          <SourcesTextField sources={sources} />
        </div>
        <div className={botResponse !== '' ? 'chatPageChatArea' : 'defaultWidth'}>
          <div className="chatPageResponse">
            {botResponse !== '' ? (
              <div className="responseAreaWrapper">
                <IoLogoElectron className="defaultScreenIcon" />
                <div className="chatPageBotResponse">{botResponse}</div>
              </div>
            ) : (
              <div className="defaultScreen">
                <img src={'/logo54.png'} alt="Logo" className="logo" />
                <h4 className="defaultScreenName">Video Search AI</h4>
              </div>
            )}
          </div>
          <div className="chatPageQuery">
            <LargeInputField
              value={query}
              label={'Query'}
              onValueChange={handleQueryChange}
            ></LargeInputField>
            <IoMdSend onClick={handleSubmit} className="chatPageConfirmButton" />
          </div>
        </div>
      </div>

      </div>
      
    </div>
  );
}

export default ChatPage;
