import './NumberInputField.css';

import React, { ChangeEvent } from 'react';

interface Props {
  value: number;
  label: string;
  onValueChange: (value: number) => void;
}

function NumberInputField({ value, label, onValueChange }: Props) {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const numericValue = parseInt(newValue, 10);

    if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 100) {
      onValueChange(numericValue);
    }
  };

  return (
    <div className="NumberInputContainer">
      <input
        type="number"
        value={value}
        placeholder={label}
        onChange={handleInputChange}
        min={0}
        max={100}
        className="NumberInputField"
      />
    </div>
  );
}

export default NumberInputField;
