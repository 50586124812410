import { IoLogoElectron } from 'react-icons/io5';
import './Upload.page.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../ui/components/LoadingSpinner';
import DropDownMenu from '../../ui/components/DropDownMenu';

interface State {
  url: string;
  title: string;
  transcript: string;
  file: File | null;
  audio: File | null;
}

interface EntityInfo{
  entityId:string,
  entityName:string,
}

function UploadPage(): JSX.Element {
  const token = localStorage.getItem('token'); // Retrieve the token
  const nav = useNavigate();
  
  const [loadingEntities,setLoadingEntities] = useState<boolean>(false);
  const [entityInfo,setEntityInfo] = useState<EntityInfo[]>([]);
  const [loadingEntitiesError, setLoadingEntitiesError] = useState<boolean>(false);
  const [selectedEntity,setSelectedEntity] = useState<EntityInfo | null>(null)
  
  const [activeForm, setActiveForm] = useState<string>('youtube');
  const [formData, setFormData] = useState<State>({
    url: '',
    title: '',
    file: null,
    transcript: '',
    audio: null,
  });



  const handleButtonClick = (formName: string) => {
    setActiveForm(formName);
    setFormData({ url: '', title: '', file: null, transcript: '', audio: null });
  };

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      // Check which form is active
      if (activeForm === 'audio') {
        // Handle audio file upload
        if (file.type.startsWith('audio')) {
          reader.onload = (e) => {
            const content = e.target?.result as string;
            setFormData({ ...formData, audio: file }); // Store base64 audio data
          };
          reader.readAsDataURL(file); // Converts file to base64
        } else {
          console.error('Invalid file type. Please upload an audio file.');
        }
      } else if (activeForm === 'transcript') {
        // Handle transcript file upload
        if (file.type === 'text/plain') {
          reader.onload = (e) => {
            const content = e.target?.result as string;
            setFormData({ ...formData, transcript: content }); // Store transcript text
          };
          reader.readAsText(file); // Reads file as plain text
        } else {
          console.error('Invalid file type. Please upload a .txt file.');
        }
      }
    }
  };

  // Function to handle YouTube URL upload
  const handleYouTubeUpload = async () => {
    const jsonData = {
      url: formData.url,
      requestedBy: 'Admin',
      entityId:selectedEntity?.entityId
    };

    await uploadData('/admin/upload/youtubeurl', jsonData);
  };

  // Function to handle audio upload
  const handleAudioUpload = async () => {
    if (!formData.audio) {
      console.error('Audio file is required.');
      return;
    }

    // Convert audio file to base64
    const reader = new FileReader();
    reader.onloadend = async () => {
      const base64Audio = reader.result?.toString().split(',')[1]; // Get base64 string without metadata

      const jsonData = {
        title: formData.title,
        url: formData.url,
        audio: base64Audio, // Include base64 audio data
        requestedBy: 'Admin',
        embedLink: null,
        entityId:selectedEntity?.entityId
      };

      await uploadData('/upload/audio', jsonData);
    };

    reader.readAsDataURL(formData.audio); // Convert file to base64
  };

  // Function to handle transcript upload
  const handleTranscriptUpload = async () => {
    const jsonData = {
      title: formData.title,
      url: formData.url,
      transcript: formData.transcript,
      requestedBy: 'Admin',
      embedLink: null,
      entityId:selectedEntity?.entityId
    };

    await uploadData('/upload/transcript', jsonData);
  };

  // Unified function to submit data
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (activeForm === 'youtube') {
      await handleYouTubeUpload();
    } else if (activeForm === 'audio') {
      await handleAudioUpload();
    } else if (activeForm === 'transcript') {
      await handleTranscriptUpload();
    }
  };

  // Helper function to upload data
  const uploadData = async (endpoint: string, jsonData: any) => {
    try {
      const uploadResponse = await fetch(`${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(jsonData),
      });

      if (!uploadResponse.ok) {
        if(uploadResponse.status === 401){
          nav('/signin');
        }
        throw new Error(`Network response was not ok: ${uploadResponse.statusText}`);
      }
    } catch (error) {
      console.error('Upload failed:', error);
    }
  };

  const requesteEntityData = async () =>{
    const url = `/admin/entities`
    try{
      setLoadingEntities(true);
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });
      if (!response.ok) {
        if(response.status === 401){
          nav('/signin');
        }
        throw new Error('Network response was not ok ' + response.statusText);
      }
      const jsonResponse = await response.json();
      const info:EntityInfo[] = JSON.parse(jsonResponse) as EntityInfo[];

      if(info.length === 0){
        console.log(`Are you an admin?`)
        throw Error(`Admin is admin of 0 entities`);
      }
      
      setEntityInfo(info);
      setSelectedEntity(info[0])
      setLoadingEntities(false);
      setLoadingEntitiesError(false)
    }catch(error){
      setLoadingEntities(false)
      setLoadingEntitiesError(true)
      console.log(`An error occured when trying to get entity data`);
      console.log(error)
    }
  }

  const handleEntitySelected = (entityName:string) =>{
    const selectedEntity = entityInfo.find(item =>( item.entityName === entityName))
    if(selectedEntity){
      setSelectedEntity(selectedEntity)
    }
  }

  useEffect(()=>{
    if(!loadingEntities && !loadingEntitiesError && entityInfo.length == 0){
      requesteEntityData();
    }
  },[loadingEntitiesError])

  if(loadingEntities){
    return <LoadingSpinner/>;
  }

  if(selectedEntity == null && entityInfo.length>0){
    return(<div>
      <DropDownMenu choices={entityInfo.map(item => item.entityName)} onSelect={handleEntitySelected}></DropDownMenu>
    </div>)
  }

  return (
    <div>
      <DropDownMenu choices={entityInfo.map(item => item.entityName)} onSelect={handleEntitySelected}></DropDownMenu>
      <div className="logoName adminLogoName">
        <IoLogoElectron className="logo" />
        <h3>Video Search AI</h3>
      </div>
      <div className="upload-page">
        <h1 className="upload-head">Upload</h1>
        <div className="buttons-bar">
          <button
            className={`upload-btns ${activeForm === 'youtube' ? 'active' : ''}`}
            onClick={() => handleButtonClick('youtube')}
          >
            YouTube URL
          </button>
          <button
            className={`upload-btns ${activeForm === 'audio' ? 'active' : ''}`}
            onClick={() => handleButtonClick('audio')}
          >
            Audio
          </button>
          <button
            className={`upload-btns ${activeForm === 'transcript' ? 'active' : ''}`}
            onClick={() => handleButtonClick('transcript')}
          >
            Transcript
          </button>
        </div>
        <div className="upload-forms">
          {activeForm === 'youtube' && (
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="url"
                value={formData.url}
                onChange={handleFormChange}
                className="upload-input"
                placeholder="Enter YouTube URL..."
                required
              />
              <button type="submit" className="submit-form">
                Submit
              </button>
            </form>
          )}

          {activeForm === 'audio' && (
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleFormChange}
                className="upload-input"
                placeholder="Enter Title..."
                required
              />
              <input
                type="text"
                name="url"
                value={formData.url}
                onChange={handleFormChange}
                className="upload-input"
                placeholder="Enter URL..."
                required
              />
              <input
                type="file"
                name="audio"
                onChange={handleFileChange}
                className="upload-input"
                placeholder="Upload audio file..."
                accept="audio/*"
                required
              />
              <button type="submit" className="submit-form">
                Submit
              </button>
            </form>
          )}

          {activeForm === 'transcript' && (
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="url"
                value={formData.url}
                onChange={handleFormChange}
                className="upload-input"
                placeholder="Enter URL..."
                required
              />
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleFormChange}
                className="upload-input"
                placeholder="Enter Title..."
                required
              />
              <input
                type="file"
                name="file"
                onChange={handleFileChange}
                className="upload-input"
                accept=".txt"
                placeholder="Upload transcript..."
                required
              />
              <button type="submit" className="submit-form">
                Submit
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

export default UploadPage;
