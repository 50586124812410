import React, { useState } from "react";
import { FaBars, FaChevronDown, FaChevronUp } from "react-icons/fa";
import './SideBar.css'; // Add some styling
import { IoMdAdd } from 'react-icons/io';
import { FaRegMessage } from "react-icons/fa6";
import { Link } from 'react-router-dom';

interface ChatHistory{
  chatHistoryId: number;
  text: string;
}

interface Props {
  chatHistory: ChatHistory[]
}


function ChatSideBar({ chatHistory }: Props):JSX.Element {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [visibleChats, setVisibleChats] = useState(10); // Initially show 4 chats
  const [isExpanded, setIsExpanded] = useState(false); // State to toggle between "Show More" and "Show Less"

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className={`sidebar ${isCollapsed ? "collapsed" : "expanded"}`}>
      <button className={`toggle-btn ${isCollapsed ? "collapsed" : "expanded"}`} onClick={toggleSidebar}>
        <FaBars />
      </button>
      
      {isCollapsed ? <></> : 
      <div>
        <div className="new-chat-btn">
        <IoMdAdd /> {!isCollapsed && <span>New Chat</span>}
      </div>
        <h4 className="recent-chats-title">{!isCollapsed && "Recent Chats"}</h4>
        <div className="chatListContainer">
      <ul className="chat-list">
        {chatHistory.slice(0, visibleChats).map((chat) => (
          <li key={chat.chatHistoryId}>
            <FaRegMessage /> <span>{chat.text}</span>
          </li>
        ))}
      </ul>
      </div>
      <div className="show-more">
        <Link to="/chat/history/search" className="show-more-btn"><span>Show More</span></Link>
      </div>
      </div>}
    </div>
  );
};

export default ChatSideBar;
