import React from 'react';
import './TranscriptTable.css';
import { Link } from 'react-router-dom';

interface PartialTranscript {
  contentDataId: number;
  url: string;
  title: string;
}

interface Props {
  data: PartialTranscript[];
  onEdit: (transcriptId: number) => void;
  onDelete: (transcriptId: number) => void;
}

function TranscriptTable({ data, onEdit, onDelete }: Props): JSX.Element {
  console.log(`showing transcript table`);
  return (
    <>
      <Link to="/admin/upload">
        <button className="upload-btn">Upload</button>
      </Link>
      <table>
        <thead>
          <tr>
            <th>Transcript ID</th>
            <th>Title</th>
            <th>URL</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.contentDataId}>
              <td>{item.contentDataId}</td>
              <td>{item.title}</td>
              <td>{item.url}</td>
              <td>
                <div className="editButtons">
                  <button className="edit" onClick={() => onEdit(item.contentDataId)}>
                    Edit
                  </button>
                  <button className="delete" onClick={() => onDelete(item.contentDataId)}>
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

export default TranscriptTable;
