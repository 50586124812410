import { useEffect, useState } from 'react';
import LoadingSpinner from '../../ui/components/LoadingSpinner';
import TranscriptTable from '../../ui/panels/TranscriptTable';
import StudyTable from '../../ui/panels/StudyTable';
import StudyForm from '../../ui/panels/StudyForm';
import Signout from '../User/SignOut/SignOut.page';
import { IoLogoElectron } from 'react-icons/io5';
import './Admin.page.css';
import DropDownMenu from '../../ui/components/DropDownMenu';
import { useNavigate } from 'react-router-dom';

const PAGE_SIZE = 20;
const isMock = process.env.REACT_APP_MOCK === 'true';

// This is a partial transcript because I'm unsure if we should allow changing more than this / knowing more than this on the front end
interface PartialTranscript {
  contentDataId: number;
  url: string;
  title: string;
}

interface StudyModel {
  studyId: number;
  transcriptId: number;
  studyUrl: string;
  studyTitle: string;
  studyAbstract: string | null;
  studyKeywords: string[] | null;
  dateAdded: string;
  dateUpdated: string | null;
  addedBy: string;
  updatedBy: string | null;
}

interface PanelData {
  transcript: PartialTranscript;
  studies: StudyModel[];
}

interface EntityInfo{
  entityId:string,
  entityName:string,
}

// TODO probably split this into seperate pages
function AdminPage(): JSX.Element {
  const nav = useNavigate();
  const token = localStorage.getItem('token'); // Retrieve the token
  
  const [loadingEntities,setLoadingEntities] = useState<boolean>(false);
  const [entityInfo,setEntityInfo] = useState<EntityInfo[]>([]);
  const [loadingEntitiesError, setLoadingEntitiesError] = useState<boolean>(false);
  const [selectedEntity,setSelectedEntity] = useState<EntityInfo | null>(null)

  // In place of a actual user system which we would probably get done in the near future
  const [pageId, setPageId] = useState<null | number>(null);
  const [primaryData, setPrimaryData] = useState<null | PanelData[]>(null);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingDataError, setLoadingDataError] = useState(false);

  // The list of transcripts to show on the transcript table
  const [transcripts, setTranscripts] = useState<PartialTranscript[]>([]);

  // Specific transcript and its associated studies / info
  const [selectedData, setSelectedData] = useState<null | PanelData>(null);
  // Study which we are looking at
  const [selectedStudy, setSelectedStudy] = useState<StudyModel | null>(null);

  // Not sure if we should allow editing transcripts?
  const [editingTranscript, setEditingTranscript] = useState(false);

  const [editingStudy, setEditingStudy] = useState(false);
  const [creatingStudy, setCreatingStudy] = useState(false);

  // used to hold the data of the newly created study or the study which we are editing
  const [temporaryStudy, setTemporaryStudy] = useState<StudyModel | null>(null);
  const [sendingStudyUpdate, setSendingStudyUpdate] = useState(false);
  const [submittingNewStudy, setSubmittingNewStudy] = useState(false);

  // Errors are tracked, however how you want to report them visualy / remove the error flags I'm unsure
  const [studyUpdateError, setStudyUpdateError] = useState(false);
  const [studyDeleteError, setStudyDeleteError] = useState(false);
  const [submittingNewStudyError, setSubmittingNewStudyError] = useState(false);

  const requesteEntityData = async () =>{
    const mockEndpoint = isMock ? '/mock' : '';
    const url = `/admin/entities${mockEndpoint}`
    try{
      setLoadingEntities(true);
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });
      if (!response.ok) {
        if(response.status === 401){
          nav('/signin');
        }
        throw new Error('Network response was not ok ' + response.statusText);
      }
      const jsonResponse = await response.json();
      const info:EntityInfo[] = JSON.parse(jsonResponse) as EntityInfo[];

      if(info.length === 0){
        console.log(`Are you an admin?`)
        throw Error(`Admin is admin of 0 entities`);
      }
      
      setEntityInfo(info);
      setSelectedEntity(info[0])
      setLoadingEntities(false);
      setLoadingEntitiesError(false)
    }catch(error){
      setLoadingEntities(false)
      setLoadingEntitiesError(true)
      console.log(`An error occured when trying to get entity data`);
      console.log(error)
    }
  }

  const handleEntitySelected = (entityName:string) =>{
    const selectedEntity = entityInfo.find(item =>( item.entityName === entityName))
    if(selectedEntity){
      setSelectedEntity(selectedEntity)
    }
  }

  const handleStudyTableBackPressed = () => {
    setSelectedData(null);
    setSelectedStudy(null);
  };

  const handleStudyTableAddPressed = () => {
    setCreatingStudy(true);
    setTemporaryStudy({
      studyId: -1,
      transcriptId: selectedData?.transcript.contentDataId,
      studyUrl: '',
      studyTitle: '',
      studyAbstract: '',
      studyKeywords: [],
      dateAdded: '',
      dateUpdated: '',
      addedBy: '',
      updatedBy: '',
    } as StudyModel);
  };

  const handleEditTranscriptPressed = (transcriptId: number) => {
    if (primaryData == null) {
      return;
    }
    for (const item of primaryData) {
      if (item.transcript.contentDataId === transcriptId) {
        setSelectedData(item);
        break;
      }
    }
  };

  const handleStudyOnEditPressed = (study: StudyModel) => {
    setSelectedStudy(study);
    if (study.studyKeywords == null) {
      setTemporaryStudy({
        ...study,
        studyKeywords: [],
      } as StudyModel);
    } else {
      setTemporaryStudy(study);
    }
    setEditingStudy(true);
  };

  const handleStudySubmitPressed = () => {
    if (editingStudy) {
      updateStudy();
    } else if (creatingStudy) {
      submitNewStudy();
    } else {
      console.log(
        'There is an error, either your editing or creating a study and somehow your neither?',
      );
    }
  };

  const handleDeleteStudyPressed = async (study: StudyModel) => {
    if (selectedData == null || selectedEntity == null) return;
    let url: string;
    const mockEndpoint = isMock ? '/mock' : '';
    url = `/admin/delete/studies${mockEndpoint}`;
    const jsonData = {
      transcriptId: selectedData?.transcript.contentDataId,
      studyId: study.studyId,
      entityId:selectedEntity.entityId,
    };
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(jsonData),
      });

      if (!response.ok) {
        if(response.status === 401){
          nav('/signin');
        }
        throw new Error('Network response was not ok ' + response.statusText);
      }
      const newStudies: StudyModel[] = [];
      for (const item of selectedData.studies) {
        if (item.studyId != study.studyId) {
          newStudies.push(item);
        }
      }
      const newPrimaryData: PanelData[] = [];
      let newSelectedItem: PanelData = selectedData;
      if (primaryData != null) {
        for (const item of primaryData) {
          if (item.transcript.contentDataId == selectedData.transcript.contentDataId) {
            const temp = { ...item, studies: newStudies };
            newSelectedItem = temp;
            newPrimaryData.push(temp);
          } else {
            newPrimaryData.push(item);
          }
        }
      }
      setSelectedData(newSelectedItem);
      setPrimaryData(newPrimaryData);
    } catch (e) {
      console.log(`An error ocurred when trying to delete:${study}`);
      setStudyDeleteError(true);
    }
  };

  const handleStudyFormBackPressed = () => {
    if (creatingStudy && !editingStudy) {
      setCreatingStudy(false);
      setTemporaryStudy(null);
    } else if (!creatingStudy && editingStudy) {
      setSelectedStudy(null);
      setTemporaryStudy(null);
      setEditingStudy(false);
    }
  };

  const submitNewStudy = async () => {
    if(selectedEntity == null) return;
    setSubmittingNewStudy(true);
    let url: string;
    const mockEndpoint = isMock ? '/mock' : '';
    url = `/admin/upload/studies${mockEndpoint}`;

    const jsonData = {
      transcriptId: selectedData?.transcript.contentDataId,
      studyUrl: temporaryStudy?.studyUrl,
      studyTitle: temporaryStudy?.studyTitle,
      studyAbstract: temporaryStudy?.studyAbstract,
      studyKeywords: temporaryStudy?.studyKeywords,
      entityId:selectedEntity.entityId,
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(jsonData),
      });

      if (!response.ok) {
        if(response.status === 401){
          nav('/signin');
        }
        throw new Error('Network response was not ok ' + response.statusText);
      }
      const result: StudyModel = await response.json();
      const studies = selectedData?.studies;
      studies?.push(result);
      const newPrimaryData: PanelData[] = [];
      if (primaryData != null && Array.isArray(primaryData) && studies != null) {
        for (const item of primaryData) {
          const itemData = item as PanelData;
          if (itemData.transcript.contentDataId == selectedData?.transcript.contentDataId) {
            newPrimaryData.push({ ...itemData, studies: studies });
          } else {
            newPrimaryData.push(itemData);
          }
        }
      }

      setPrimaryData(newPrimaryData);
      setSelectedStudy(null);
      setTemporaryStudy(null);
      setSubmittingNewStudy(false);
    } catch (error) {
      console.error('There was a problem with uploading the study:', error);
      if (error instanceof TypeError) {
        console.error('TypeError details:', error.message);
      }
      setSubmittingNewStudyError(true);
    }
  };

  const updateStudy = async () => {
    if (selectedData == null || selectedEntity == null) return;
    setSendingStudyUpdate(true);
    let url: string;
    const mockEndpoint = isMock ? '/mock' : '';
    url = `/admin/update/studies${mockEndpoint}`;
    const jsonData = {
      transcriptId: selectedData?.transcript.contentDataId,
      studyId: selectedStudy?.studyId,
      studyUrl: temporaryStudy?.studyUrl,
      studyTitle: temporaryStudy?.studyTitle,
      studyAbstract: temporaryStudy?.studyAbstract,
      studyKeywords: temporaryStudy?.studyKeywords,
      dateAdded: selectedStudy?.dateAdded,
      entityId:selectedEntity.entityId,
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(jsonData),
      });

      if (!response.ok) {
        if(response.status === 401){
          nav('/signin');
        }
        throw new Error('Network response was not ok ' + response.statusText);
      }

      const studies: StudyModel[] = [];
      if (selectedData != null) {
        for (const item of selectedData.studies) {
          if (item == selectedStudy && temporaryStudy != null) {
            studies.push(temporaryStudy);
          } else {
            studies.push(item);
          }
        }
      }
      const newPrimaryData: PanelData[] = [];
      let newSelectedData: PanelData = selectedData;
      if (primaryData != null && Array.isArray(primaryData) && studies != null) {
        for (const item of primaryData) {
          const itemData = item as PanelData;
          if (itemData.transcript.contentDataId == selectedData?.transcript.contentDataId) {
            const temp = { ...itemData, studies: studies } as PanelData;
            newPrimaryData.push(temp);
            newSelectedData = temp;
          } else {
            newPrimaryData.push(itemData);
          }
        }
      }
      setPrimaryData(newPrimaryData);
      setSelectedData(newSelectedData);
      setTemporaryStudy(null);
      setSelectedStudy(null);
      setEditingStudy(false);
    } catch (error) {
      console.error('There was a problem with uploading the study:', error);
      if (error instanceof TypeError) {
        console.error('TypeError details:', error.message);
      }
      setStudyUpdateError(true);
    }
  };

  const requestTranscripts = async () => {
    if(!selectedEntity){
      return;
    }
    
    try{
      let url: string;
    const mockEndpoint = isMock ? '/mock' : '';
    url = `/admin/transcripts${mockEndpoint}`;
    const jsonData = {
      pageSize:PAGE_SIZE,
      entityId:selectedEntity.entityId,
      transcriptId:pageId
    }
    setLoadingData(true);
      const response = await fetch(url, {
        method: "POST", // Change to POST
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}` // Include the Bearer Token
        },
        body: JSON.stringify(jsonData) // Send data in the body
      });
  
      if (!response.ok) {
        if(response.status === 401){
          nav('/signin');
        }
        throw new Error(`An issue occurred retrieving transcripts`);
      }
  
      const responseData = await response.json();
      const result: PanelData[] = responseData.map((item: PanelData) => ({
        transcript: item.transcript as PartialTranscript,
        studies: item.studies,
      }));
  
      setPrimaryData(result);
      const transcriptArray: PartialTranscript[] = result.map((item) => item.transcript);
      setTranscripts(transcriptArray);
      setLoadingData(false);
    } catch (err) {
      console.error(`Error fetching transcripts:`, err);
      setLoadingData(false);
      setLoadingDataError(true);
    }
  };

  const handleEditedStudyChangeTitle = (newName: string) => {
    setTemporaryStudy({
      ...temporaryStudy,
      studyTitle: newName, // Update only the studyTitle property
    } as StudyModel);
  };

  const handleEditedStudyChangeUrl = (newUrl: string) => {
    setTemporaryStudy({
      ...temporaryStudy,
      studyUrl: newUrl,
    } as StudyModel);
  };

  const handleEditedStudyChangeAbstract = (newAbstract: string) => {
    setTemporaryStudy({
      ...temporaryStudy,
      studyAbstract: newAbstract, // Update only the studyTitle property
    } as StudyModel);
  };

  const handleEditedStudyAddKeyword = (newKeyword: string) => {
    const keywords = temporaryStudy?.studyKeywords;
    keywords?.push(newKeyword);
    setTemporaryStudy({
      ...temporaryStudy,
      studyKeywords: keywords, // Update only the studyTitle property
    } as StudyModel);
  };

  const handleEditedStudyRemoveKeyword = (removeKeyword: string) => {
    const keywords: string[] = [];
    if (temporaryStudy?.studyKeywords != null) {
      for (const item of temporaryStudy?.studyKeywords) {
        if (item !== removeKeyword) {
          keywords.push(item);
        }
      }
    }
    setTemporaryStudy({
      ...temporaryStudy,
      studyKeywords: keywords, // Update only the studyTitle property
    } as StudyModel);
  };

  useEffect(()=>{
    if(!loadingEntities && !loadingEntitiesError && entityInfo.length == 0){
      requesteEntityData();
    }
  },[loadingEntitiesError])
  
  // TODO is this a valid spot for this realy?
  useEffect(()=>{
    if (!loadingData && primaryData == null && !loadingDataError && selectedEntity != null) {
      requestTranscripts();
    }
  },[selectedEntity]);
  

  if (loadingData) {
    return <LoadingSpinner/>;
  }

  if(loadingEntities){
    return <LoadingSpinner/>;
  }

  if(selectedEntity == null && entityInfo.length>0){
    return(<div>
      <DropDownMenu choices={entityInfo.map(item => item.entityName)} onSelect={handleEntitySelected}></DropDownMenu>
    </div>)
  }

  if (primaryData != null) {
    return (
      <div>
        <DropDownMenu choices={entityInfo.map(item => item.entityName)} onSelect={handleEntitySelected}></DropDownMenu>
        {selectedData == null && (
          <div>
            <div className="headerWrapper">
              <div className="logoName adminLogoName">
                <IoLogoElectron className="logo" />
                <h3>Video Search AI</h3>
              </div>
              <div>
                {' '}
                <Signout />
              </div>
            </div>
            {/* <p>Transcripts</p> */}
            <TranscriptTable
              data={transcripts}
              onEdit={handleEditTranscriptPressed}
              onDelete={function (transcriptId: number): void {
                // TODO this needs to now be hooked up
                console.log(`Need to discuss if we want transcripts deletable here`);
              }}
            ></TranscriptTable>
          </div>
        )}
        {selectedData != null && selectedStudy == null && temporaryStudy == null && (
          <div className="editPage">
            <button className="backButton" onClick={handleStudyTableBackPressed}>
              Back
            </button>
            <p className="transcriptTitle">Title: {selectedData.transcript.title}</p>
            <a href={selectedData.transcript.url}>
              <p className="transcriptURL">URL: {selectedData.transcript.url}</p>
            </a>
            <StudyTable
              data={selectedData.studies}
              onEdit={handleStudyOnEditPressed}
              onDelete={handleDeleteStudyPressed}
            ></StudyTable>
            <button className="addStudyButton" onClick={handleStudyTableAddPressed}>
              Add Study
            </button>
          </div>
        )}
        {temporaryStudy != null &&
          temporaryStudy.studyTitle != null &&
          temporaryStudy.studyUrl != null &&
          temporaryStudy.studyAbstract != null &&
          temporaryStudy.studyKeywords != null && (
            <div>
              {creatingStudy ? '' : 'Editing Study'}
              <StudyForm
                onSubmit={handleStudySubmitPressed}
                onBack={handleStudyFormBackPressed}
                onNameChange={handleEditedStudyChangeTitle}
                onAbstractChange={handleEditedStudyChangeAbstract}
                onUrlChange={handleEditedStudyChangeUrl}
                onKeywordAdded={handleEditedStudyAddKeyword}
                onKeywordRemoved={handleEditedStudyRemoveKeyword}
                isLoading={sendingStudyUpdate || submittingNewStudy}
                studyName={temporaryStudy.studyTitle}
                studyUrl={temporaryStudy.studyUrl}
                studyAbstract={temporaryStudy.studyAbstract}
                studyKeywords={temporaryStudy.studyKeywords}
              ></StudyForm>
            </div>
          )}
      </div>
    );
  }

  return <p>If your seeing this something bad happened, maybe the server is down?</p>;
}

export default AdminPage;
